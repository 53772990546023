



























































































































































@import '~variables'

  .cursor-pointer
    cursor pointer
  ul li div
    color black
    width 100%
    padding 5px
    border-radius 4px
    background #f3f3f3
    i
      font-size 0.9em
      margin-right 10px
  ul li
    margin 10px
  ul.selected li:last-child div
    background #aaaaaa
    color white

  .filter-container
    width 500px

  span.select-btn
    display none

  ul.selected li:last-child span.select-btn
    display inline-block
    float: right

  .q-card-title
    position relative
    .btn-select-container
      position absolute
      right 15px
      top 15px
  .spinner-loader
    stroke $primary
    fill $primary

  .select-container
    max-height 400px
    overflow auto

::-webkit-scrollbar {
    width: 3px;
}
::-webkit-scrollbar-track {
    background: #2f2f2f;
}
::-webkit-scrollbar-thumb {
    background: #828282;
}
::-webkit-scrollbar-thumb:hover {
    background: #b9b9b9;
}
