

















































@import '~variables'

.sidebar-in
  overflow auto
  height 100%
  .sidebar-item
    border-bottom $border-thick solid $silver
    &:last-child
      border-bottom none
