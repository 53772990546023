@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.control-time {
  padding: 16px 8px;
  padding-top: 0;
}
.control-time .button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.control-time .button-container .o-button {
  margin: 0 6px;
  padding: 2px 11px;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: 400;
}
.control-time .date-input-container {
  display: flex;
  justify-content: center;
}
.control-time .date-input-container .control-time__date-input {
  z-index: 10;
  margin-right: 5px;
  padding: 2px;
  height: 30px;
  font-size: 1.3rem;
  cursor: pointer;
  user-select: none;
}
.control-time .date-input-container .date-input__chevron {
  margin: 0 12px;
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
}
.control-time .date-input-container .date-input__hours {
  z-index: 5;
  padding: 2px;
  height: 30px;
  color: #b4b2a9;
  font-size: 1.3rem;
  cursor: pointer;
  user-select: none;
}
.control-time .date-input-container .selectedDrawer {
  height: 23px;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
}
.control-time .calendar-drawer {
  position: absolute;
  top: 23px;
  left: 100%;
  transition: all 0.3s ease;
}
@media screen and (max-width: 500px) {
  .control-time .calendar-drawer {
    top: 170px;
    right: 0;
    left: 0;
    z-index: 100;
    margin: 0 auto;
  }
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*# sourceMappingURL=src/app/pages/oql/components/selection/time.css.map */