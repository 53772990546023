


































































@import '~variables'

.location
  padding 20px
  .location__device, .location__node
    width 600px
    border-bottom solid $border-light $grey
  .location__device
    margin 0 auto
  .location__node
    margin 20px auto 0 auto
