

















































































































































































































































@import '~variables'

.control-time
  padding 16px 8px
  padding-top 0
  .button-container
    display flex
    justify-content center
    margin-bottom 24px
    .o-button
      margin 0 6px
      padding 2px 11px
      background-color white
      text-transform uppercase
      font-weight 400
  .date-input-container
    display flex
    justify-content center
    .control-time__date-input
      z-index 10
      margin-right 5px
      padding 2px
      height 30px
      font-size $fs-h2
      cursor pointer
      user-select none
    .date-input__chevron
      margin 0 12px
      width 20px
      height @width
      transform rotate(-90deg)
    .date-input__hours
      z-index 5
      padding 2px
      height 30px
      color $grey
      font-size 1.3rem
      cursor pointer
      user-select none
    .selectedDrawer
      height 23px
      border-radius 4px
      background-color black
      color white
  .calendar-drawer
    position absolute
    top 23px
    left 100%
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      top 170px
      right 0
      left 0
      z-index 100
      margin 0 auto

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
