





















































































@import '~variables'

.building-facts
  min-height auto
  height 100%
  .left-sidebar >>> aside
    position fixed
    top 50px
    overflow visible // to see the drawer
    box-shadow $shadow-elevation-3
  .left-sidebar.no-transition >>> aside
    transform translateX(0px)
  .sidebar-out
    z-index 2

.viewer
  position relative
  padding 10px
  .results-table
    margin 10px auto
    background-color white
  .spinner-loader
    position absolute
    top 300px
    left 50%
    margin-left -40px

::-webkit-scrollbar
  width 6px

::-webkit-scrollbar-track
  background #cacaca

::-webkit-scrollbar-thumb
  background #828282

::-webkit-scrollbar-thumb:hover
  background #000
