@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.building-facts {
  min-height: auto;
  height: 100%;
}
.building-facts .left-sidebar >>> aside {
  position: fixed;
  top: 50px;
  overflow: visible;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.building-facts .left-sidebar.no-transition >>> aside {
  transform: translateX(0px);
}
.building-facts .sidebar-out {
  z-index: 2;
}
.viewer {
  position: relative;
  padding: 10px;
}
.viewer .results-table {
  margin: 10px auto;
  background-color: #fff;
}
.viewer .spinner-loader {
  position: absolute;
  top: 300px;
  left: 50%;
  margin-left: -40px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #cacaca;
}
::-webkit-scrollbar-thumb {
  background: #828282;
}
::-webkit-scrollbar-thumb:hover {
  background: #000;
}
/*# sourceMappingURL=src/app/pages/oql/query-builder.css.map */