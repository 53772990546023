














































@import '~variables'
.options
    padding-right 50px
    .options__limit
        border-bottom solid $border-light $grey
