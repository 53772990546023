
































































































































































































































































































@import '~variables'
.list-items
  padding 10px
  .list-items__item
    font-size $fs-h3
    font-weight 700
