

















































































































@import '~variables'

.topology-drawer
  position absolute
  left 100%
  min-height 210px
  transition all 0.3s ease
  @media screen and (max-width: 500px)
    top 170px
    right 0
    left 0
    z-index 100
    margin 0 auto

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
