@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.cursor-pointer {
  cursor: pointer;
}
ul li div {
  color: #000;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  background: #f3f3f3;
}
ul li div i {
  font-size: 0.9em;
  margin-right: 10px;
}
ul li {
  margin: 10px;
}
ul.selected li:last-child div {
  background: #aaa;
  color: #fff;
}
.filter-container {
  width: 500px;
}
span.select-btn {
  display: none;
}
ul.selected li:last-child span.select-btn {
  display: inline-block;
  float: right;
}
.q-card-title {
  position: relative;
}
.q-card-title .btn-select-container {
  position: absolute;
  right: 15px;
  top: 15px;
}
.spinner-loader {
  stroke: #f7bbbf;
  fill: #f7bbbf;
}
.select-container {
  max-height: 400px;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #2f2f2f;
}
::-webkit-scrollbar-thumb {
  background: #828282;
}
::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
}
/*# sourceMappingURL=src/app/pages/oql/components/Topology/Filter.css.map */