
































































































































































.main-card
  background-color white
